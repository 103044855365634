import * as React from 'react';
import * as _ from 'lodash';
import cx from 'classnames';
import {IProduct, IVideoFile} from '../../../../types/productDef';
import {ImageMode, ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {Swipeable} from 'react-swipeable';
import {
  getMediaUrl,
  getFullSizedMediaUrl,
  getMainImageRatio,
} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {classes} from './ResponsiveGallery.st.css';
import s from './ResponsiveGallery.scss';
import {DotNavigation} from 'wix-ui-tpa/DotNavigation';
import {ImageRatio} from '../../../../constants';
import {NoProduct} from '../../../../icons/dist';
import {IMediaItem} from '../../../../types/app-types';

const WIXVIDEO_BASEURL = 'https://video.wixstatic.com/';

export enum ResponsiveGalleryDataHook {
  Media = 'responsive-gallery-media',
  MediaContainer = 'responsive-gallery-media-container',
  NavigationButton = 'responsive-gallery-navigation',
  NoMedia = 'responsive-gallery-no-media',
}

export interface ResponsiveGalleryProps {
  imageMode: ImageMode;
  imageRatio?: ImageRatio;
  maxRatio?: number;
  media: IProduct['media'];
  openZoom?(): void;
  withZoom?: boolean;
}

interface ResponsiveGalleryState {
  currentIndex: number;
  currentEffect: 'fadeout' | 'fadein';
  media: IProduct['media'];
  renderWidth: number;
}

export class ResponsiveGallery extends React.Component<ResponsiveGalleryProps, ResponsiveGalleryState> {
  private readonly ref: React.RefObject<HTMLDivElement>;
  private readonly defaultRenderWidth = 100;

  public static defaultProps = {
    imageRatio: ImageRatio.AUTO,
    openZoom: _.noop,
    classNames: {},
  };

  public constructor(props: ResponsiveGalleryProps) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      currentEffect: null,
      currentIndex: 0,
      media: [],
      renderWidth: this.defaultRenderWidth,
    };
  }

  private get ratio() {
    const {media, imageRatio, maxRatio} = this.props;
    const mainRatio = getMainImageRatio(media[0], imageRatio);
    const {height, width} = imageRatio === ImageRatio.AUTO ? media[0] : mainRatio.ratio;
    const ratio = height / width;

    if (maxRatio && ratio > maxRatio) {
      return maxRatio;
    }
    return ratio;
  }

  private readonly navigateToMedia = (currentIndex: number) => {
    /* istanbul ignore if: todo(sagi) */
    if (this.props.media.length <= 1) {
      return;
    }

    this.setState({currentEffect: 'fadeout'});
    setTimeout(() => {
      this.setState({currentIndex}, () => {
        setTimeout(() => {
          this.setState({currentEffect: 'fadein'});
        }, 100);
      });
    }, 100);
  };

  /* istanbul ignore next: todo(sagi) */
  private readonly goRight = () => {
    let nextIndex = this.state.currentIndex - 1;
    if (nextIndex === -1) {
      nextIndex = this.props.media.length - 1;
    }
    this.navigateToMedia(nextIndex);
  };

  /* istanbul ignore next: todo(sagi) */
  private readonly goLeft = () => {
    let nextIndex = this.state.currentIndex + 1;
    if (nextIndex > this.props.media.length - 1) {
      nextIndex = 0;
    }
    this.navigateToMedia(nextIndex);
  };

  private readonly renderMedia = () => {
    const {currentEffect, currentIndex} = this.state;
    const {media, openZoom, imageMode: imageMode, withZoom} = this.props;
    const currentMedia = media[currentIndex];
    const src = getMediaUrl(currentMedia, this.getMediaDimensions(currentMedia));
    const video = currentMedia.mediaType === 'VIDEO' && currentMedia.videoFiles && currentMedia.videoFiles[0];
    const backgroundImage = video ? 'none' : `url(${src})`;
    const backgroundSize = imageMode === ImageModeValues.CROP ? 'cover' : 'contain';
    const paddingTop = Math.ceil(this.ratio * 100);

    return (
      <div data-hook={ResponsiveGalleryDataHook.MediaContainer}>
        <Swipeable onSwipedLeft={this.goLeft} onSwipedRight={this.goRight} trackMouse={false}>
          <div
            onClick={openZoom}
            data-hook={ResponsiveGalleryDataHook.Media}
            className={cx(s.media, s[currentEffect], {
              'modal-zoom-icon': withZoom,
            })}
            style={{backgroundImage, backgroundSize, paddingTop: `${paddingTop}%`}}>
            {video && this.renderVideo(video)}
          </div>
        </Swipeable>
      </div>
    );
  };

  private renderVideo(video: IVideoFile) {
    const src = WIXVIDEO_BASEURL + video.url;
    return <video controls src={src} />;
  }

  private readonly renderNavigation = () => {
    const {media} = this.props;
    const {currentIndex} = this.state;

    if (media.length <= 1) {
      return;
    }

    return (
      <div className={s.navigation}>
        <DotNavigation
          currentIndex={currentIndex}
          onSelect={(i) => this.navigateToMedia(i)}
          data-hook={ResponsiveGalleryDataHook.NavigationButton}
          length={media.length}
          className={classes.root}
        />
      </div>
    );
  };

  public static getDerivedStateFromProps(
    props: ResponsiveGalleryProps,
    state: ResponsiveGalleryState
  ): {currentIndex: 0; media: IProduct['media']} {
    if (!_.isEqual(props.media, state.media)) {
      return {currentIndex: 0, media: props.media};
    }
    return null;
  }

  public componentDidMount(): void {
    this.setState({renderWidth: this.ref.current.clientWidth});
  }

  public render(): JSX.Element {
    const {media} = this.props;
    return (
      <div data-hook="responsive-gallery" ref={this.ref}>
        {media.length > 0 ? this.renderMedia() : this.renderNoMedia()}
        {this.renderNavigation()}
        {this.renderSEO()}
      </div>
    );
  }

  private renderNoMedia() {
    return (
      <div data-hook={ResponsiveGalleryDataHook.NoMedia} className={s.noMedia}>
        <NoProduct />
      </div>
    );
  }

  private getMediaDimensions(media: IMediaItem) {
    const width = this.state.renderWidth || this.defaultRenderWidth;
    const height = (media.height / media.width) * width;
    return {height, width};
  }

  private renderSEO() {
    const {media} = this.props;
    return (
      <ul className={s.seo_only}>
        {media.map((m) => (
          <li key={`media-${m.url}`}>
            <img src={getFullSizedMediaUrl(m)} alt={m.altText} />
          </li>
        ))}
      </ul>
    );
  }
}
